


.page .pageHeader {
    position: relative;
    display: flex;

    width: calc(100% - 10px * 2);
    height: 30px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    margin-left: 10px;

    border-bottom: 1px solid #ddd;
}

.page .pageHeader .icon.headerIcon {
    width: 20px;
    height: 20px;
    padding: 5px;
}
.page .pageHeader .text.titleHolder {
    line-height: 30px;
    font-size: 18px;
}

.page .content {
    position: relative;
    grid-area: content;
}
