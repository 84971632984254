
.popupContainer {
    position: fixed;
    display: flex;
    justify-content: center;

    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .2);
    transition: all .3s;
}
.popupContainer.hide {
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
}

.popupContainer .popup.pairMenu {
    position: absolute;
    top: calc(45vh);

    width: calc(100% - 20px * 2);
    max-width: 300px;
    height: auto;
    
    padding: 20px;
    box-shadow: 5px 5px 200px 100px rgba(0, 0, 0, .15);
    background-color: #fff;
    z-index: 10;
    
    transform: translateY(-50%);

    text-align: center;
    color: #444;

    border-radius: 5px;
    transition: all .3s;
}

.popupContainer .popup.pairMenu.hide {
    opacity: 0;
    transform: translateY(calc(-50% + 50px));
    pointer-events: none;
}






@media screen and (max-width: 500px) {
    .popupContainer .popup.pairMenu {
        top: 100vh;
    
        max-width: 100vw;
        height: auto;
        padding-bottom: 40px !important;
        border-radius: 15px;

        transform: translateY(-100%);
    }

    .popupContainer .popup.pairMenu.hide {
        transform: translateY(calc(-100% + 150px));
    }
}







.popup.pairMenu .loader {
    margin-top: 15px;
    margin-bottom: 15px;
}
.popup.pairMenu .loader .icon.loadCircle {
    margin: auto;
    width: 50px;
    height: auto;
    opacity: .3;
    margin-bottom: 10px;
}

.popup.pairMenu .loader .icon.text {
    color: #444;
}




.popup.pairMenu.pairSingleDevice {
    padding: 10px 20px;
}
.popup.pairMenu.pairSingleDevice.binding {
    pointer-events: none;
    background-color: red;
}

.popup.pairMenu.pairSingleDevice.binding div {
    opacity: 0;
}


.popup.pairMenu.pairSingleDevice .icon.deviceIcon {
    position: relative;
    width: calc(30%);
    padding: 20px;
    margin-left: calc(35% - 20px);
    margin-top: 5px;
    margin-bottom: 5px;
}

.popup.pairMenu.pairSingleDevice:not(.hide) .icon.deviceIcon {
    animation: showDeviceAnimation 1s 1;
}

@keyframes showDeviceAnimation {
    0% {
        top: 50px;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}


.popup.pairMenu.pairSingleDevice .text {
    transition: opacity .3s;
}

.popup.pairMenu.pairSingleDevice .text.header {
    position: relative;
    height: 20px;

    text-align: left;
    font-size: 16px;
    line-height: 30px;
    white-space: nowrap;
    color: #337;
}

.popup.pairMenu .button.text {
    color: #444;
}
.popup.pairMenu.pairSingleDevice .button.text.bindButton {
    color: #44f;
}



.popup.pairMenu.deviceList {
    padding: 15px 20px;
}

.popup.pairMenu.deviceList .deviceOption {
    position: relative;
    height: 20px;
    padding: 5px 0;
    border-bottom: 1px solid #eef;
    margin-bottom: 5px;
}
.popup.pairMenu.deviceList .deviceOption:nth-last-child(2) {
    border-bottom: none;
    /* margin-bottom: 10px; */
}

.popup.pairMenu.deviceList .deviceOption .text.title {
    text-align: left;
    float: left;
    color: #337;
    line-height: 20px;
    width: calc(100% - 30px);
}
.popup.pairMenu.deviceList .deviceOption .onSameNetworkIndicator {
    height: 20px;
    width: 20px;
    float: right;
    background-color: red;
    opacity: .5;
}
.popup.pairMenu.deviceList .button.text.cancelButton {
    margin-bottom: -5px;
}

