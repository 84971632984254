

.DV.GraphHolder {
  position: relative;
  display: grid;
  width: 100%;
  height: inherit;
  grid-template-areas:
      'yAxis canvas'
      'empty xAxis';
  grid-template-rows: auto 20px;
  grid-template-columns: 20px auto;
  row-gap: 0;
  column-gap: 0;
}

.DV.GraphHolder canvas {
  grid-area: canvas;
  width: 100%;
  height: calc(100% - 10px);
}


.DV.GraphHolder .AxisText {
  position: relative;
	font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;

  color: #777;
	font-size: 10px;
  text-align: center;
  white-space: nowrap;
  line-height: 10px;
  height: 10px;
}

.DV.GraphHolder .AxisText.xAxisTag {
  grid-area: xAxis;
  margin-top: -5px;
}

.DV.GraphHolder .yAxisTagHolder {
  grid-area: yAxis;
  width: 20px;
  height: 100%;
}

.DV.GraphHolder .AxisText.yAxisTag {
  position: relative;
  width: 1000px;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%) rotateZ(-90deg);
}
