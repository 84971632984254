.App {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: grid;
    grid-template-areas: 'sideBar mainContent';
    grid-template-columns: 300px auto;
}

