
#sideBar {
  grid-area: sideBar;
  width: calc(100% - 20px * 2);
  padding: 20px;

  /* background: linear-gradient(to bottom right, rgba(0, 255, 0, .3), rgba(0, 200, 255, .4)); */
  background: url('../images/sidebar.jpg');
  background-size: 100% 100%;
  box-shadow: 5px 5px 10px 10px rgba(180, 100, 40, .1);
}

#sideBar .text {
  color: #fff;
}



#sideBar .deviceList {
  position: relative;
  width: calc(100% + 20px);
  height: auto;
  max-height: calc(100% - 100px);
}

#sideBar .header {
  position: relative;
  display: grid;
  width: 100%;

  grid-template-areas: 'icon title status';
  grid-template-columns: 25px auto 25px;
  height: 30px;
}

#sideBar .header .icon {
  grid-area: icon;
  width: 20px;
  height: 20px;
  padding: 5px;
  padding-left: 0;
}

#sideBar .header .text.title {
  grid-area: title;
  width: 100%;
  height: 30px;
  
  line-height: 30px;
}
#sideBar .header .text.status {
  line-height: 30px;
}
#sideBar .header .text.status.offline {
  color: #666;
}
#sideBar .header .text.status.online {
  color: #4f4;
}
#sideBar .header .text.status.error {
  color: #fa4;
}